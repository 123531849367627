import React, { ChangeEvent, DragEvent, useEffect, useRef, useState } from 'react'
import useSize from '@react-hook/size'

import type { validatorFunc } from '@/utils/validatorProvider'
import Validator from '@/components/form/validator/Validator'
import iconDownload from '@assets/form/icon-download.png'

import { Document, Thumbnail } from 'react-pdf'

import './File.css'

interface FileProps {
	id: string
	placeholder: string
	label: string
	validator?: validatorFunc
	onChange?: (value: string) => void
	style?: React.CSSProperties
}

function File(props: FileProps) {
	const [dragActive, setDragActive] = useState(false)
	const [url, setUrl] = useState<string>('')
	const [file, setFile] = useState<File | null>(null)
	const [errors, setErrors] = useState<string[]>([])
	const ref = useRef<any>({ value: '' })
	const refFileContent = React.useRef(null)
	const [width] = useSize(refFileContent)

	useEffect(() => {
		return () => {
			if (url) {
				URL.revokeObjectURL(url)
			}
		}
	}, []) //eslint-disable-line

	const handleDrag = function (e: DragEvent<HTMLDivElement>) {
		e.preventDefault()
		e.stopPropagation()
		if (e.type === 'dragenter' || e.type === 'dragover') {
			setDragActive(true)
		} else if (e.type === 'dragleave') {
			setDragActive(false)
		}
	}

	const handleDrop = function (e: DragEvent<HTMLDivElement>) {
		e.preventDefault()
		e.stopPropagation()
		setDragActive(false)
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			// at least one file has been dropped so do something
			// handleFiles(e.dataTransfer.files);
			const data = e.dataTransfer.files[0]
			setFile(data)
			const url = URL.createObjectURL(data)
			setUrl(url)
			ref.current.value = url
			if (props.onChange) {
				props.onChange(url)
			}
		}
	}

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault()
		if (e.target.files && e.target.files[0]) {
			const data = e.target.files[0]
			setFile(data)
			const url = URL.createObjectURL(data)
			setUrl(url)
			ref.current.value = url
			if (props.onChange) {
				props.onChange(url)
			}
		}
	}

	const fileRender = () => {
		const imgRender = () => {
			return <img src={url} alt={file?.name} />
		}

		const pdfRender = () => {
			return (
				<Document file={file}>
					<Thumbnail pageNumber={1} width={width} />
				</Document>
			)
		}

		if (file && url !== '') {
			return (
				<div className='fileRender'>
					<p>{file.name}</p>
					{file.type === 'application/pdf' ? pdfRender() : imgRender()}
				</div>
			)
		}
		return (
			<div className='fileRender'>
				<span className='filePlaceholder'>+</span>
			</div>
		)
	}

	const handleError = (errors: string[]) => {
		setErrors(errors)
	}

	return (
		<Validator id={props.id} validator={props.validator} reference={ref} onError={handleError}>
			<div className='fileBox' onDragEnter={handleDrag} style={props.style}>
				<input
					className='input-file-upload'
					type='file'
					id={`${props.id}-input-file-upload`}
					multiple={false}
					onChange={handleChange}
				/>
				<label id='label-file-upload' htmlFor={`${props.id}-input-file-upload`}>
					<div className='fileHeader'>
						<h3>{props.label}</h3>
						<img src={iconDownload} alt='upload' />
					</div>
					<div className='fileContent' ref={refFileContent}>
						{fileRender()}
					</div>
				</label>
				{dragActive && (
					<div
						id='drag-file-element'
						onDragEnter={handleDrag}
						onDragLeave={handleDrag}
						onDragOver={handleDrag}
						onDrop={handleDrop}
					></div>
				)}
				{errors.map((error, index) =>
					error !== '' ? (
						<span key={index} className='error text-center'>
							{error}
						</span>
					) : null,
				)}
			</div>
		</Validator>
	)
}

export default File
