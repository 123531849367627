import React from 'react'

import './Button.css'

interface ButtonProps {
	id?: string
	children: React.ReactNode
	onClick: (e: React.MouseEvent<HTMLDivElement>) => void
}
function Button(props: ButtonProps) {
	return (
		<div id={props.id} className='button' onClick={props.onClick}>
			{props.children}
		</div>
	)
}

export default Button
