import React from 'react'
import { setTermsAndConditions } from '@components/form/sections/termsSlice'
import { useAppDispatch } from '@/hooks'

import CheckAndLabel from '../check/CheckAndLabel'

function Terms() {
	const dispatch = useAppDispatch()

	const id = 'termsAndConditions'

	const handleSetTerms = (value: boolean) => {
		console.log('Terms: ', value)
		dispatch(setTermsAndConditions(value))
	}

	return (
		<CheckAndLabel
			id={id}
			name={id}
			text="J'ai lu et j'accepte les conditions générales de vente"
			onChange={handleSetTerms}
		/>
	)
}

export default Terms
