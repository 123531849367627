import React from 'react'

import Button from '@components/form/buttons/Button'

import './ButtonIconText.css'

interface ButtonIconTextProps {
	id?: string
	icon: string
	text: string
	invertIcon?: boolean
	onClick: (e: React.MouseEvent<HTMLDivElement>) => void
}

function ButtonIconText(props: ButtonIconTextProps) {
	if (props.invertIcon) {
		return (
			<div id={props.id} className='buttonIconAndText invert'>
				<Button onClick={props.onClick}>
					<div className='buttonContent'>
						{props.text}
						<img src={props.icon} alt={props.text} />
					</div>
				</Button>
			</div>
		)
	}

	return (
		<div className='buttonIconAndText'>
			<Button id={props.id} onClick={props.onClick}>
				<div className='buttonContent'>
					<img src={props.icon} alt={props.text} />
					{props.text}
				</div>
			</Button>
		</div>
	)
}

export default ButtonIconText
