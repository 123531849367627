import Title from '../title/Title'
import File from '../files/File'
import add from '@assets/form/plus.svg'
import { useAppDispatch, useAppSelector } from '@/hooks'
import type { RootState } from '@/store'
import type { IPerson } from './personSlice'
import type { ICorporation } from './corporationSlice'
import { setIdFile, setNonFinancingDeclarationFile } from '@components/form/sections/personSlice'
import {
	setCorporationKbisFile,
	setCorporationStatutsFile,
} from '@components/form/sections/corporationSlice'
import {
	setCorporationDelegateIdFile,
	setCorporationNonFinancingDeclarationFile,
} from '@components/form/sections/corporationSlice'
import { requiredValidator } from '@/utils/validators'

import './Section.css'

interface FilesProps {
	buyerType: string
	fundingType: string
}

function Files(props: FilesProps) {
	const dispatch = useAppDispatch()

	// **** getters ****
	const persons = useAppSelector((state: RootState) => state.person.persons)
	const corporations = useAppSelector((state: RootState) => state.corporation.corporations)

	const fullName = (person: IPerson) => {
		return `${person.firstName} ${person.lastName}`
	}

	const corpoDelegateFullName = (corpo: ICorporation) => {
		return `${corpo.corporationDelegateFirstName} ${corpo.corporationDelegateLastName}`
	}

	const handleSetIdFile = (id: string, idFile: string) => {
		dispatch(setIdFile({ id: id, value: idFile }))
	}

	const handleSetKbis = (path: string) => {
		dispatch(setCorporationKbisFile(path))
	}

	const handleSetStatus = (path: string) => {
		dispatch(setCorporationStatutsFile(path))
	}

	const handleSetNonFinancingDeclaration = (id: string, path: string) => {
		if (props.buyerType === 'person') {
			dispatch(setNonFinancingDeclarationFile({ id: id, value: path }))
		} else {
			dispatch(setCorporationNonFinancingDeclarationFile({ id: id, value: path }))
		}
	}

	const handleSetCorpoId = (id: string, idFile: string) => {
		dispatch(setCorporationDelegateIdFile({ id: id, value: idFile }))
	}

	return (
		<div className='section'>
			<Title>Vos documents</Title>
			{props.buyerType === 'person' &&
				Object.values(persons).map(person => (
					<div key={person.id}>
						<div className='underSectionSeparator'>{fullName(person)}</div>
						<div className='filesContent'>
							<File
								id={`${person.id}-id`}
								label={'Pièce d’identité'}
								placeholder={add}
								validator={(input: string) => [requiredValidator(input)]}
								onChange={path => handleSetIdFile(person.id, path)}
								style={{ cursor: 'pointer' }}
							/>
							{props.fundingType === 'cash' && (
								<File
									id={`${person.id}-ficanancingDeclaration`}
									label={'Renonciation de financement'}
									placeholder={add}
									validator={(input: string) => [requiredValidator(input)]}
									onChange={path => handleSetNonFinancingDeclaration(person.id, path)}
									style={{ cursor: 'pointer' }}
								/>
							)}
						</div>
					</div>
				))}
			{props.buyerType === 'corporation' &&
				Object.values(corporations).map(corporation => (
					<div key={corporation.corporationId}>
						<div className='underSectionSeparator'>{corpoDelegateFullName(corporation)}</div>
						<div className='filesContent'>
							<File
								key={`${corporation.corporationId}-id`}
								id={`${corporation.corporationId}-id`}
								label={'Pièce d’identité'}
								placeholder={add}
								validator={(input: string) => [requiredValidator(input)]}
								onChange={path => handleSetCorpoId(corporation.corporationId, path)}
								style={{ cursor: 'pointer' }}
							/>
							<File
								key={`${corporation.corporationId}-kbis`}
								id={`${corporation.corporationId}-kbis`}
								label='Kbis'
								placeholder={add}
								validator={(input: string) => [requiredValidator(input)]}
								onChange={kbis => handleSetKbis(kbis)}
								style={{ cursor: 'pointer' }}
							/>
							<File
								key={`${corporation.corporationId}-status`}
								id={`${corporation.corporationId}-status`}
								label='Status'
								placeholder={add}
								validator={(input: string) => [requiredValidator(input)]}
								onChange={status => handleSetStatus(status)}
								style={{ cursor: 'pointer' }}
							/>
							{props.fundingType === 'cash' && (
								<File
									key={`${corporation.corporationId}-ficanancingDeclaration`}
									id={`${corporation.corporationId}-ficanancingDeclaration`}
									label={'Renonciation de financement'}
									placeholder={add}
									validator={(input: string) => [requiredValidator(input)]}
									onChange={path =>
										handleSetNonFinancingDeclaration(corporation.corporationId, path)
									}
									style={{ cursor: 'pointer' }}
								/>
							)}
						</div>
					</div>
				))}
		</div>
	)
}

export default Files
