import LabelAndPhoneInput from '../inputs/LabelAndPhoneInput'
import LabelAndTextInput from '../inputs/LabelAndTextInput'
import ButtonIconText from '@components/form/buttons/ButtonIconText'
import Title from '../title/Title'
import Pictogram from '@components/form/pictogram/Pictogram'
import person from '@assets/form/person.svg'
import deleteicon from '@assets/form/DeleteIcon.svg'
import corporation from '@assets/form/moral.svg'
import SelectBoxAndLabel from '../select/SelectBoxAndLabel'
import RadioGroupAndLabel from '../radio/RadioGroupAndLabel'
import { useAppDispatch, useAppSelector } from '@/hooks'
import {
	setAddress,
	setBirthDate,
	setBirthCity,
	setBirthCountry,
	setChildren,
	setContractDate,
	setContractPlace,
	setContractType,
	setEmail,
	setFirstName,
	setGender,
	setHasContract,
	setLastName,
	setMarriageCity,
	setMarriageDate,
	setMarried,
	setNationality,
	setPhone,
	setProfession,
} from '@components/form/sections/personSlice'
import {
	setCorporationAddress,
	setCorporationCapital,
	setCorporationDelegateAddress,
	setCorporationDelegateEmail,
	setCorporationDelegateFirstName,
	setCorporationDelegateGender,
	setCorporationDelegateLastName,
	setCorporationDelegatePhone,
	setCorporationName,
	setCorporationRegistar,
	setCorporationSiren,
} from '@components/form/sections/corporationSlice'
import type { RootState } from '@/store'
import {
	defaultInputValidator,
	emailValidator,
	requiredValidator,
	defaultNoNumberInputValidator,
	numberValidator,
} from '@/utils/validators'

import './Section.css'

interface Section2Props {
	id: string
	position: number
	onRemoveBuyer: (id: string) => void
	buyerType: string
	onSetBuyerType: (value: string) => void
}

function Section2(props: Section2Props) {
	const handleRemoveBuyer = () => {
		props.onRemoveBuyer(props.id)
	}

	const handleSetBuyerType = (value: string) => {
		props.onSetBuyerType(value)
	}

	const getPersons = useAppSelector((state: RootState) => state.person.persons)

	return (
		<div className='section'>
			<div className='headerSection'>
				<Title>Vos coordonnées</Title>
				{Object.keys(getPersons).length > 1 && (
					<ButtonIconText
						id='suppButton'
						icon={deleteicon}
						text='Supprimer ce co-acquéreur'
						onClick={handleRemoveBuyer}
					/>
				)}
			</div>
			<div className='pictograms'>
				{(props.position === 0 || props.buyerType === 'person') && (
					<Pictogram
						className={'picto1' + (props.buyerType === 'person' ? ' active' : '')}
						src={person}
						id='person'
						onChange={() => handleSetBuyerType('person')}
						style={{ cursor: 'pointer' }}
					>
						Personne physique
					</Pictogram>
				)}
				{(props.position === 0 || props.buyerType === 'corporation') && (
					<Pictogram
						className={'picto1' + (props.buyerType === 'corporation' ? ' active' : '')}
						src={corporation}
						id='corporation'
						onChange={() => handleSetBuyerType('corporation')}
						style={{ cursor: 'pointer' }}
					>
						Personne morale
					</Pictogram>
				)}
			</div>
			{props.buyerType === 'person' && <Person id={props.id} />}
			{props.buyerType === 'corporation' && <Corporation id={props.id} position={props.position} />}
		</div>
	)
}

interface PersonProps {
	id: string
}

function computeID(id: string, name: string): string {
	return `${id}-${name}`
}

function Person(props: PersonProps) {
	const dispatch = useAppDispatch()

	const getGender = useAppSelector(
		(state: RootState) => state.person.persons[props.id]?.gender ?? '',
	)
	const onSetGender = (value: string) => {
		dispatch(setGender({ id: props.id, value }))
	}

	const getLastName = useAppSelector(
		(state: RootState) => state.person.persons[props.id]?.lastName ?? '',
	)
	const onSetLastName = (value: string) => {
		dispatch(setLastName({ id: props.id, value }))
	}

	const getFirstName = useAppSelector(
		(state: RootState) => state.person.persons[props.id]?.firstName ?? '',
	)
	const onSetFirstName = (value: string) => {
		dispatch(setFirstName({ id: props.id, value }))
	}

	const getPhone = useAppSelector((state: RootState) => state.person.persons[props.id]?.phone ?? '')

	const onSetPhone = (value: string | undefined) => {
		dispatch(setPhone({ id: props.id, value }))
	}

	const getEmail = useAppSelector((state: RootState) => state.person.persons[props.id]?.email ?? '')
	const onSetEmail = (value: string) => {
		dispatch(setEmail({ id: props.id, value }))
	}

	const getBirthDate = useAppSelector(
		(state: RootState) => state.person.persons[props.id]?.birthDate ?? '',
	)
	const onSetBirthDate = (value: string) => {
		dispatch(setBirthDate({ id: props.id, value }))
	}

	const getBirthCity = useAppSelector(
		(state: RootState) => state.person.persons[props.id]?.birthCity ?? '',
	)
	const onSetBirthCity = (value: string) => {
		dispatch(setBirthCity({ id: props.id, value }))
	}

	const getBirthCountry = useAppSelector(
		(state: RootState) => state.person.persons[props.id]?.birthCountry ?? '',
	)
	const onSetBirthCountry = (value: string) => {
		dispatch(setBirthCountry({ id: props.id, value }))
	}

	const getNationality = useAppSelector(
		(state: RootState) => state.person.persons[props.id]?.nationality ?? '',
	)
	const onSetNationality = (value: string) => {
		dispatch(setNationality({ id: props.id, value }))
	}

	const getProfession = useAppSelector(
		(state: RootState) => state.person.persons[props.id]?.profession ?? '',
	)
	const onSetProfession = (value: string) => {
		dispatch(setProfession({ id: props.id, value }))
	}

	const getAddress = useAppSelector(
		(state: RootState) => state.person.persons[props.id]?.address ?? '',
	)
	const onSetAddress = (value: string) => {
		dispatch(setAddress({ id: props.id, value }))
	}

	const getMarried = useAppSelector(
		(state: RootState) => state.person.persons[props.id]?.married ?? '',
	)
	const onSetMarried = (value: string) => {
		dispatch(setMarried({ id: props.id, value }))
	}

	const getChildren = useAppSelector(
		(state: RootState) => state.person.persons[props.id]?.children ?? '',
	)
	const onSetChildren = (value: string) => {
		dispatch(setChildren({ id: props.id, value }))
	}

	const getHasContract = useAppSelector(
		(state: RootState) => state.person.persons[props.id]?.hasContract ?? '',
	)
	const onSetHasContract = (value: string) => {
		dispatch(setHasContract({ id: props.id, value }))
	}

	return (
		<div>
			<div className='formContent'>
				<div className='fitContentLine'>
					<SelectBoxAndLabel
						id={computeID(props.id, 'personGender')}
						text='Civilité'
						options={{ '': 'Sélectionner', mr: 'M', mrs: 'Mme' }}
						value={getGender}
						validator={(input: string) => [requiredValidator(input)]}
						onChange={onSetGender}
					/>
				</div>
				<div className='fieldLine'>
					<LabelAndTextInput
						id={computeID(props.id, 'personLastname')}
						label='Nom'
						name='lastName'
						autocomplete='family-name'
						placeholder='Ex : Dupont'
						value={getLastName}
						validator={defaultNoNumberInputValidator}
						onChange={onSetLastName}
					/>
					<LabelAndTextInput
						id={computeID(props.id, 'personFirstname')}
						label='Prénom'
						name='firstName'
						autocomplete='given-name'
						placeholder='Ex : Jean'
						value={getFirstName}
						validator={defaultNoNumberInputValidator}
						onChange={onSetFirstName}
					/>
				</div>
				<div className='fieldLine'>
					<LabelAndPhoneInput
						id={computeID(props.id, 'personPhone')}
						label='Téléphone'
						name='phone'
						placeholder='06xxxxxxxx'
						value={getPhone}
						onChange={onSetPhone}
						required={true}
					/>
					<LabelAndTextInput
						id={computeID(props.id, 'personEmail')}
						label='Adresse mail'
						name='email'
						autocomplete='email'
						value={getEmail}
						placeholder='jean.dupont@gmail.com'
						validator={(input: string) => [emailValidator(input)]}
						onChange={onSetEmail}
					/>
				</div>
				<div className='fieldLine'>
					<LabelAndTextInput
						id={computeID(props.id, 'personBirthDate')}
						type='date'
						name='birthDate'
						autocomplete='bday'
						label='Date de naissance'
						placeholder='jj/mm/aaaa'
						value={getBirthDate}
						validator={(input: string) => [requiredValidator(input)]}
						onChange={onSetBirthDate}
					/>
					<LabelAndTextInput
						id={computeID(props.id, 'personNationality')}
						label='Nationalité'
						name='nationality'
						autocomplete='nationality'
						placeholder='Ex : Française'
						value={getNationality}
						validator={defaultNoNumberInputValidator}
						onChange={onSetNationality}
					/>
				</div>
				<div className='fieldLine'>
					<LabelAndTextInput
						id={computeID(props.id, 'personBirthCity')}
						label='Ville de naissance'
						name='city'
						autocomplete='address-level2'
						placeholder='Ex : Paris'
						value={getBirthCity}
						validator={defaultNoNumberInputValidator}
						onChange={onSetBirthCity}
					/>
					<LabelAndTextInput
						id={computeID(props.id, 'personBirthCountry')}
						name='country'
						label='Pays de naissance'
						autocomplete='country'
						placeholder='Ex : France'
						value={getBirthCountry}
						validator={defaultNoNumberInputValidator}
						onChange={onSetBirthCountry}
					/>
				</div>
				<div className='fieldLine'>
					<LabelAndTextInput
						id={computeID(props.id, 'personProfession')}
						label='Profession'
						name='job'
						autocomplete='job'
						placeholder='Ex : artisan'
						value={getProfession}
						validator={defaultNoNumberInputValidator}
						onChange={onSetProfession}
					/>
					<LabelAndTextInput
						id={computeID(props.id, 'personAddress')}
						label='Adresse'
						name='address'
						autocomplete='street-address'
						placeholder='Ex : 12 rue de Paris 75000 Paris'
						value={getAddress}
						validator={defaultInputValidator}
						onChange={onSetAddress}
					/>
				</div>
				<div className='fieldLine'>
					<SelectBoxAndLabel
						id={computeID(props.id, 'personMarried')}
						text='Status marital'
						options={{
							'': 'Sélectionner',
							single: 'Célibataire',
							married: 'Marié',
							civilUnion: 'Pacsé',
							divorced: 'Divorcé',
							widowhood: 'Veuf',
						}}
						value={getMarried}
						validator={(input: string) => [requiredValidator(input)]}
						onChange={onSetMarried}
					/>
					<LabelAndTextInput
						id={computeID(props.id, 'personChildren')}
						type='number'
						label='Nombre d’enfants'
						name='children'
						autocomplete='number'
						placeholder='Ex : 0'
						value={getChildren}
						validator={(input: string) => [requiredValidator(input), numberValidator(input)]}
						onChange={onSetChildren}
					/>
				</div>
				{(getMarried === 'married' || getMarried === 'civilUnion') && <Marriage id={props.id} />}
				{(getMarried === 'married' || getMarried === 'civilUnion') && (
					<div className='fieldLine'>
						<RadioGroupAndLabel
							id={computeID(props.id, 'personHasContract')}
							label='Avez-vous un contrat de mariage / PACS ?'
							options={{ no: 'Non', yes: 'Oui' }}
							defaultChecked={getHasContract}
							validator={(input: string) => [requiredValidator(input)]}
							onChange={onSetHasContract}
						/>
					</div>
				)}
			</div>
			{getHasContract === computeID(props.id, 'personHasContract') + '-yes' && (
				<Contract id={props.id} />
			)}
		</div>
	)
}

interface MarriageProps {
	id: string
}

function Marriage(props: MarriageProps) {
	const dispatch = useAppDispatch()

	const getMarriageCity = useAppSelector(
		(state: RootState) => state.person.persons[props.id]?.marriageCity ?? '',
	)
	const onSetMarriageCity = (value: string) => {
		dispatch(setMarriageCity({ id: props.id, value }))
	}

	const getMarriageDate = useAppSelector(
		(state: RootState) => state.person.persons[props.id]?.marriageDate ?? '',
	)
	const onSetMarriageDate = (value: string) => {
		dispatch(setMarriageDate({ id: props.id, value }))
	}

	return (
		<div
			className='formContent'
			style={{
				margin: '0',
			}}
		>
			<div className='fieldLine'>
				<LabelAndTextInput
					id={computeID(props.id, 'personMarriageCity')}
					label='Ville du mariage / PACS'
					name='city'
					placeholder='Ville'
					value={getMarriageCity}
					onChange={onSetMarriageCity}
					validator={defaultNoNumberInputValidator}
					autocomplete='address-level2'
				/>
				<LabelAndTextInput
					id={computeID(props.id, 'personMarriageDate')}
					type='date'
					name='marriageDate'
					label='Date du mariage / PACS'
					placeholder='jj/mm/aaaa'
					value={getMarriageDate}
					onChange={onSetMarriageDate}
					validator={(input: string) => [requiredValidator(input)]}
				/>
			</div>
		</div>
	)
}

interface ContractProps {
	id: string
}

function Contract(props: ContractProps) {
	const dispatch = useAppDispatch()

	const getContractDate = useAppSelector(
		(state: RootState) => state.person.persons[props.id]?.contractDate ?? '',
	)
	const onSetContractDate = (value: string) => {
		dispatch(setContractDate({ id: props.id, value }))
	}

	const getContractPlace = useAppSelector(
		(state: RootState) => state.person.persons[props.id]?.contractPlace ?? '',
	)
	const onSetContractPlace = (value: string) => {
		dispatch(setContractPlace({ id: props.id, value }))
	}

	const getContractType = useAppSelector(
		(state: RootState) => state.person.persons[props.id]?.contractType ?? '',
	)
	const onSetContractType = (value: string) => {
		dispatch(setContractType({ id: props.id, value }))
	}

	return (
		<div className='formContent'>
			<div className='fieldLine'>
				<LabelAndTextInput
					id={computeID(props.id, 'contractDate')}
					type='date'
					name='contractDate'
					label='Date du contrat'
					placeholder='jj/mm/aaaa'
					value={getContractDate}
					onChange={onSetContractDate}
					validator={(input: string) => [requiredValidator(input)]}
				/>
				<LabelAndTextInput
					id={computeID(props.id, 'contractPlace')}
					label='Lieu de signature'
					name='city'
					placeholder='Ville'
					value={getContractPlace}
					onChange={onSetContractPlace}
					validator={defaultNoNumberInputValidator}
					autocomplete='address-level2'
				/>
				<SelectBoxAndLabel
					id={computeID(props.id, 'contractType')}
					text='Régime'
					options={{
						'': 'Sélectionner',
						universalCommunity: 'Communauté universelle',
						separated: 'Séparation de biens',
						participation: 'Participation aux acquêts',
						acquittalsCommunity: 'Communauté réduite aux acquêts',
					}}
					value={getContractType}
					validator={(input: string) => [requiredValidator(input)]}
					onChange={onSetContractType}
				/>
			</div>
		</div>
	)
}

interface CorporationProps {
	id: string
	position: number
}

function Corporation(props: CorporationProps) {
	const dispatch = useAppDispatch()

	const getCorporationName = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.id]?.corporationName ?? '',
	)
	const onSetCorporationName = (value: string) => {
		dispatch(setCorporationName({ id: props.id, value }))
	}

	const getCorporationCapital = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.id]?.corporationCapital ?? '',
	)
	const onSetCorporationCapital = (value: string) => {
		dispatch(setCorporationCapital({ id: props.id, value }))
	}

	const getCorporationAddress = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.id]?.corporationAddress ?? '',
	)
	const onSetCorporationAddress = (value: string) => {
		dispatch(setCorporationAddress({ id: props.id, value }))
	}

	const getCorporationSiren = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.id]?.corporationSiren ?? '',
	)
	const onSetCorporationSiren = (value: string) => {
		dispatch(setCorporationSiren({ id: props.id, value }))
	}

	const getCorporationRegistar = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.id]?.corporationRegistar ?? '',
	)
	const onSetCorporationRegistar = (value: string) => {
		dispatch(setCorporationRegistar({ id: props.id, value }))
	}

	const getCorporationDelegateGender = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.id]?.corporationDelegateGender ?? '',
	)
	const onSetCorporationDelegateGender = (value: string) => {
		dispatch(setCorporationDelegateGender({ id: props.id, value }))
	}

	const getCorporationDelegateLastName = useAppSelector(
		(state: RootState) =>
			state.corporation.corporations[props.id]?.corporationDelegateLastName ?? '',
	)
	const onSetCorporationDelegateLastName = (value: string) => {
		dispatch(setCorporationDelegateLastName({ id: props.id, value }))
	}

	const getCorporationDelegateFirstName = useAppSelector(
		(state: RootState) =>
			state.corporation.corporations[props.id]?.corporationDelegateFirstName ?? '',
	)
	const onSetCorporationDelegateFirstName = (value: string) => {
		dispatch(setCorporationDelegateFirstName({ id: props.id, value }))
	}

	const getCorporationDelegatePhone = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.id]?.corporationDelegatePhone ?? '',
	)
	const onSetCorporationDelegatePhone = (value: string | undefined) => {
		dispatch(setCorporationDelegatePhone({ id: props.id, value }))
	}

	const getCorporationDelegateEmail = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.id]?.corporationDelegateEmail ?? '',
	)
	const onSetCorporationDelegateEmail = (value: string) => {
		dispatch(setCorporationDelegateEmail({ id: props.id, value }))
	}

	const getCorporationDelegateAddress = useAppSelector(
		(state: RootState) =>
			state.corporation.corporations[props.id]?.corporationDelegateAddress ?? '',
	)
	const onSetCorporationDelegateAddress = (value: string) => {
		dispatch(setCorporationDelegateAddress({ id: props.id, value }))
	}

	return (
		<div className='formContent'>
			{props.position === 0 && (
				<>
					<div className='fieldLine'>
						<LabelAndTextInput
							id={computeID(props.id, 'corporationName')}
							label='Raison sociale'
							name='corporationName'
							autocomplete='organization'
							placeholder="Ex : SAS de l'Étoile"
							value={getCorporationName}
							onChange={onSetCorporationName}
							validator={defaultNoNumberInputValidator}
						/>
						<LabelAndTextInput
							id={computeID(props.id, 'corporationCapital')}
							label='Capital social'
							name='corporationCapital'
							placeholder='Ex : 1 500€'
							value={getCorporationCapital}
							onChange={onSetCorporationCapital}
							validator={(input: string) => [requiredValidator(input), numberValidator(input)]}
							autocomplete='on'
						/>
					</div>
					<div className='fieldLine'>
						<LabelAndTextInput
							id={computeID(props.id, 'corporationAddress')}
							name='address'
							autocomplete='street-address'
							label='Adresse de la société'
							placeholder='Ex : chemin de l’Etoile 06000 Nice'
							value={getCorporationAddress}
							onChange={onSetCorporationAddress}
							validator={defaultInputValidator}
						/>
					</div>
					<div className='fieldLine'>
						<LabelAndTextInput
							id={computeID(props.id, 'corporationSiren')}
							label='SIREN'
							name='siren'
							placeholder='Ex : 123456789'
							value={getCorporationSiren}
							onChange={onSetCorporationSiren}
							validator={(input: string) => [requiredValidator(input), numberValidator(input)]}
							autocomplete='on'
						/>
						<LabelAndTextInput
							id={computeID(props.id, 'corporationRegistar')}
							label='Ville où a été enregistrée la société'
							name='city'
							placeholder='Ex : Nice'
							value={getCorporationRegistar}
							onChange={onSetCorporationRegistar}
							validator={defaultNoNumberInputValidator}
							autocomplete='address-level2'
						/>
					</div>
				</>
			)}
			<div className='underSectionSeparator'>Représentant de la société</div>
			<div className='fitContentLine'>
				<SelectBoxAndLabel
					id={computeID(props.id, 'corporationDelegateGender')}
					text='Civilité'
					options={{ '': 'Sélectionner', mr: 'M', mrs: 'Mme' }}
					value={getCorporationDelegateGender}
					validator={(input: string) => [requiredValidator(input)]}
					onChange={onSetCorporationDelegateGender}
				/>
			</div>
			<div className='fieldLine'>
				<LabelAndTextInput
					id={computeID(props.id, 'corporationDelegateLastname')}
					label='Nom'
					name='lastName'
					placeholder='Ex : Dupont'
					value={getCorporationDelegateLastName}
					onChange={onSetCorporationDelegateLastName}
					validator={defaultNoNumberInputValidator}
					autocomplete='family-name'
				/>
				<LabelAndTextInput
					id={computeID(props.id, 'corporationDelegateFirstname')}
					label='Prénom'
					name='firstName'
					placeholder='Ex : Jean'
					value={getCorporationDelegateFirstName}
					onChange={onSetCorporationDelegateFirstName}
					validator={defaultNoNumberInputValidator}
					autocomplete='given-name'
				/>
			</div>
			<div className='fieldLine'>
				<LabelAndPhoneInput
					id={computeID(props.id, 'corporationDelegatePhone')}
					label='Téléphone'
					name='phone'
					placeholder='06xxxxxxxx'
					value={getCorporationDelegatePhone}
					onChange={onSetCorporationDelegatePhone}
					required={true}
				/>
				<LabelAndTextInput
					id={computeID(props.id, 'corporationDelegateEmail')}
					label='Adresse mail'
					name='email'
					placeholder='Ex : jean.dupont@leficaneur.com'
					value={getCorporationDelegateEmail}
					onChange={onSetCorporationDelegateEmail}
					validator={(input: string) => [emailValidator(input)]}
					autocomplete='email'
				/>
			</div>
			<div className='fieldLine'>
				<LabelAndTextInput
					id={computeID(props.id, 'corporationDelegateAddress')}
					label='Adresse'
					name='address'
					placeholder="Ex : Chemin de l'Étoile 06000 Nice"
					value={getCorporationDelegateAddress}
					onChange={onSetCorporationDelegateAddress}
					validator={defaultInputValidator}
					autocomplete='street-address'
				/>
			</div>
		</div>
	)
}

export default Section2
