import React, { useRef, useState } from 'react'

import Label from '../labels/Label'
import RadioAndLabel from './RadioAndLabel'
import type { validatorFunc } from '@/utils/validatorProvider'
import Validator from '@/components/form/validator/Validator'

import './RadioGroupAndLabel.css'

interface RadioGroupAndLabelProps {
	id: string
	label: string
	options: { [key: string]: string }
	validator?: validatorFunc
	onChange: (value: string) => void
	defaultChecked?: string
	style?: React.CSSProperties
}

function RadioGroupAndLabel(props: RadioGroupAndLabelProps) {
	const [error, setError] = useState<string[]>([])
	// The validator will check the `value` value, so we create a object with a value property
	const ref = useRef<any>({ value: '' })

	const handleChange = (data: string) => {
		props.onChange(data)
		ref.current.value = data
	}

	const handleError = (errors: string[]) => {
		setError(errors)
	}

	return (
		<Validator id={props.id} validator={props.validator} reference={ref} onError={handleError}>
			<div style={props.style} className='RadioGroupAndLabel'>
				<Label htmlFor={props.id}>{props.label}</Label>
				<div className='RadioGroupContent'>
					{Object.keys(props.options).map(key => (
						<RadioAndLabel
							id={`${props.id}-${key}`}
							key={key}
							label={props.options[key]}
							name={props.id}
							defaultChecked={props.defaultChecked}
							onChange={handleChange}
						/>
					))}
				</div>
				{error.map((error, index) =>
					error !== '' ? (
						<span key={index} className='error'>
							{error}
						</span>
					) : null,
				)}
			</div>
		</Validator>
	)
}

export default RadioGroupAndLabel
