import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@/hooks'
import { nanoid } from 'nanoid'

import Section1 from './sections/Section1'
import Section2 from './sections/Section2'
import Funding from './sections/Funding'
import Notary from './sections/Notary'
import Files from './sections/Files'
import Terms from './sections/Terms'
import ButtonIconText from '@components/form/buttons/ButtonIconText'
import { useAppDispatch } from '@/hooks'
import { addPerson, removePerson } from '@components/form/sections/personSlice'
import { addCorporation, removeCorporation } from '@components/form/sections/corporationSlice'
import arrow from '@assets/form/arrow.svg'
import ajouter from '@assets/form/ajouter.svg'
import type { RootState } from '@/store'
import { useValidatorContext } from '@/utils/validatorProvider'
import Modal from '@components/form/Modal'
import modalArrow from '@assets/form/modal-arrow.png'
import Button from '@components/form/buttons/Button'

import './Form.css'

let initDone = 0

function Form() {
	const validator = useValidatorContext()
	const [buyerType, setBuyerType] = useState('')
	const [fundingType, setFundingType] = useState('')
	const [openModal, setOpenModal] = useState(false)
	const [readonly, setReadonly] = useState(false)

	const dispatch = useAppDispatch()

	// **** getters ****
	const persons = useAppSelector((state: RootState) => state.person.persons)
	const corporations = useAppSelector((state: RootState) => state.corporation.corporations)
	const funding = useAppSelector((state: RootState) => state.funding)
	const notary = useAppSelector((state: RootState) => state.notary)

	const handleAddBuyer = () => {
		const id = nanoid()
		dispatch(addPerson(id))
		dispatch(addCorporation(id))
	}

	useEffect(() => {
		if (initDone === 0) {
			initDone = 1
			handleAddBuyer()
		}
	}, []) //eslint-disable-line

	const handleSetBuyerType = (value: string) => {
		setBuyerType(value)
	}

	const handleSetFundingType = (value: string) => {
		setFundingType(value)
	}

	const handleRemoveBuyer = (id: string) => {
		dispatch(removePerson(id))
		dispatch(removeCorporation(id))
	}

	const handleCloseValidatedModal = () => {
		setReadonly(true)
		setOpenModal(false)
	}

	const modalContent = (
		<div id='modalBody'>
			<h1>Votre formulaire a bien été envoyé</h1>
			<img src={modalArrow} alt='arrow' />
			<p>Vous recevrez un email dans quelques instants.</p>
			<div id='modalButtons'>
				<Button onClick={handleCloseValidatedModal}>Fermer</Button>
			</div>
		</div>
	)

	const handleSubmit = () => {
		console.log('handleSubmit')
		console.log('persons: ' + JSON.stringify(persons))
		console.log('corporations: ' + JSON.stringify(corporations))
		console.log('funding: ' + JSON.stringify(funding))
		console.log('notary: ' + JSON.stringify(notary))
		const validated = validator.validate()
		setOpenModal(validated)
	}

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.code === 'Enter' || event.code === 'NumpadEnter') {
			event.preventDefault()
			handleSubmit()
		}
	}

	const formClass = readonly ? 'readonly' : ''

	const addButtonText =
		buyerType === 'corporation' ? 'Ajouter un représentant' : 'Ajouter un co-acquéreur'

	return (
		<form
			id='form'
			className={formClass}
			onKeyDown={handleKeyDown}
			onSubmit={e => e.preventDefault()}
			noValidate
		>
			<div className='header'>
				<p id='logo'></p>
				<h1 id='program'>Les hauts de Cannes</h1>
				<h2 id='city'>Cannes</h2>
			</div>
			<p id='description'>Formulaire de renseignement pour votre acquisition immobilière</p>
			<Section1 />
			{Object.values(persons).map((person, index) => (
				<Section2
					key={person.id}
					id={person.id}
					position={index}
					onRemoveBuyer={handleRemoveBuyer}
					buyerType={buyerType}
					onSetBuyerType={handleSetBuyerType}
				/>
			))}
			<ButtonIconText
				icon={ajouter}
				text={addButtonText}
				onClick={e => {
					e.preventDefault()
					handleAddBuyer()
				}}
			/>
			<Funding fundingType={fundingType} onSetFundingType={handleSetFundingType} />
			<Notary />
			<Files buyerType={buyerType} fundingType={fundingType} />
			<Terms />
			<ButtonIconText
				id='send'
				invertIcon={true}
				text='Envoyer'
				icon={arrow}
				onClick={() => {
					handleSubmit()
				}}
			/>
			{openModal && <Modal>{modalContent}</Modal>}
		</form>
	)
}

export default Form
