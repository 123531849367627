import React, { useState } from 'react'

import Label from '../labels/Label'
import type { validatorFunc } from '@/utils/validatorProvider'
import Validator from '@/components/form/validator/Validator'

import './LabelAndTextInput.css'

interface LabelAndTextInputProps {
	id: string
	name?: string
	type?: string
	label: string
	placeholder?: string
	value?: string
	readOnly?: Object
	onChange: (value: string) => void
	validator?: validatorFunc
	autocomplete?: string
	style?: React.CSSProperties
}

const LabelAndTextInput = (props: LabelAndTextInputProps) => {
	const [errors, setErrors] = useState<string[]>([])
	const ref = React.createRef<HTMLInputElement>()

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.onChange(e.target.value)
	}

	const handleError = (errors: string[]) => {
		setErrors(errors)
	}

	return (
		<Validator id={props.id} validator={props.validator} reference={ref} onError={handleError}>
			<div style={props.style} className='LabelAndTextInput'>
				<Label htmlFor={props.id}>{props.label}</Label>
				<input
					type={props.type ?? 'text'}
					placeholder={props.placeholder}
					id={props.id}
					value={props.value}
					ref={ref}
					name={props.name ?? props.id}
					{...props.readOnly}
					onChange={handleChange}
					autoComplete={props.autocomplete ?? 'off'}
				/>
				{errors.map((error, index) =>
					error !== '' ? (
						<span key={index} className='error'>
							{error}
						</span>
					) : null,
				)}
			</div>
		</Validator>
	)
}

export default LabelAndTextInput
