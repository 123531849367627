import React, { useState, useRef } from 'react'

import Validator from '@/components/form/validator/Validator'
import { requiredCheckedValidator } from '@/utils/validators'
import Label from '../labels/Label'

import './CheckAndLabel.css'

interface CheckAndLabelProps {
	id: string
	name: string
	text: string
	onChange: (value: boolean) => void
}

function CheckAndLabel(props: CheckAndLabelProps) {
	const ref = useRef<any>({ value: false })
	const [errors, setErrors] = useState<string[]>([])

	const validator = (input: string) => [
		requiredCheckedValidator(input, "Veuillez accepter les conditions d'utilisation."),
	]

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.onChange(e.currentTarget.checked)
		ref.current.value = e.currentTarget.checked
	}

	const handleError = (errors: string[]) => {
		setErrors(errors)
	}

	return (
		<Validator id={props.id} validator={validator} reference={ref} onError={handleError}>
			<div className='CheckAndLabel'>
				<div className='flex'>
					<input
						type='checkbox'
						id={props.id}
						name={props.name}
						value={props.id}
						onChange={handleChange}
					/>
					<Label htmlFor={props.id}>{props.text}</Label>
				</div>
				{errors.map((error, index) =>
					error !== '' ? (
						<span key={index} className='error'>
							{error}
						</span>
					) : null,
				)}
			</div>
		</Validator>
	)
}

export default CheckAndLabel
