import React, { useState } from 'react'

import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

import Label from '../labels/Label'
import type { validatorFunc } from '@/utils/validatorProvider'
import Validator from '@/components/form/validator/Validator'

import './LabelAndTextInput.css'

interface LabelAndPhoneInputProps {
	id: string
	type?: string
	label: string
	name?: string
	placeholder?: string
	value?: string
	required?: boolean
	readOnly?: Object
	onChange: (value: string | undefined) => void
	style?: React.CSSProperties
	autocomplete?: string
}

function LabelAndPhoneInput(props: LabelAndPhoneInputProps) {
	const [errors, setErrors] = useState<string[]>([])
	const ref = React.createRef<any>()

	const handleChange = (phone: string | undefined) => {
		props.onChange(phone)
	}

	const validator: validatorFunc = value => {
		if (!isValidPhoneNumber(value, 'FR')) {
			return [{ valid: false, error: 'Numéro de téléphone incorrect' }]
		}
		return [{ valid: true, error: '' }]
	}

	const handleError = (errors: string[]) => {
		setErrors(errors)
	}

	return (
		<Validator
			id={props.id}
			validator={props.required ? validator : undefined}
			reference={ref}
			onError={handleError}
		>
			<div style={props.style} className='LabelAndTextInput'>
				<Label htmlFor={props.id}>{props.label}</Label>
				<PhoneInput
					id={props.id}
					placeholder={props.placeholder}
					value={props.value}
					name={props.name ?? props.id}
					defaultCountry='FR'
					onChange={handleChange}
					autoComplete={props.autocomplete ?? 'tel'}
					ref={ref}
				/>
				{errors.map((error, index) =>
					error !== '' ? (
						<span key={index} className='error'>
							{error}
						</span>
					) : null,
				)}
			</div>
		</Validator>
	)
}
export default LabelAndPhoneInput
