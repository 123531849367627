import type { validationResult } from './validatorProvider'

export const minLengthValidator = (input: string, minLength: number): validationResult => {
	if (input.length < minLength) {
		return {
			valid: false,
			error: `Le champ doit contenir au moins ${minLength} caractères.`,
		}
	}

	return { valid: true, error: '' }
}

export const requiredValidator = (
	input: string,
	message: string = 'Ce champ est requis.',
): validationResult => {
	if (!input.trim()) {
		return {
			valid: false,
			error: message,
		}
	}

	return { valid: true, error: '' }
}

export const requiredCheckedValidator = (
	input: string,
	message: string = 'Ce champ est requis.',
): validationResult => {
	if (!input) {
		return {
			valid: false,
			error: message,
		}
	}

	return { valid: true, error: '' }
}

export const emailValidator = (input: string): validationResult => {
	var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ //eslint-disable-line

	if (!input.match(mailformat)) {
		return {
			valid: false,
			error: 'Adresse email invalide.',
		}
	}

	return { valid: true, error: '' }
}

export const noNumberValidator = (input: string): validationResult => {
	var numberFormat = /[0-9]/

	if (input.match(numberFormat)) {
		return {
			valid: false,
			error: 'Ce champ ne peut pas contenir de chiffres.',
		}
	}

	return { valid: true, error: '' }
}

export const numberValidator = (input: string): validationResult => {
	var numberFormat = /^[0-9]*$/

	if (!input.match(numberFormat)) {
		return {
			valid: false,
			error: "Ce champ ne peut contenir qu'un nombre.",
		}
	}

	return { valid: true, error: '' }
}

export const decimalValidator = (input: string): validationResult => {
	var decimalFormat = /^\d+([\.,]\d{1,2})?$/ //eslint-disable-line

	if (!input.match(decimalFormat)) {
		return {
			valid: false,
			error: "Ce champ ne peut contenir qu'un nombre.",
		}
	}

	return { valid: true, error: '' }
}

export const defaultInputValidator = (input: string) => {
	return [requiredValidator(input), minLengthValidator(input, 2)]
}

export const defaultNoNumberInputValidator = (input: string) => {
	return [requiredValidator(input), minLengthValidator(input, 2), noNumberValidator(input)]
}
